import React, { useRef } from "react"
import PropTypes from "prop-types"
import tw, { styled } from "twin.macro"
import parse from "html-react-parser"
import Button from "@elements/Button"
import { useStateContext } from "@context/stateContext"
import MovingElement from "@elements/MovingElement"

const Section = styled.section`
  ${tw`
		relative
		w-full
		z-10
    bg-blue
    pt-c120 md:pt-c96 2xl:pt-c120
    pb-c40 md:pb-c32 2xl:pb-c40
  `}
  box-shadow: 0px 15px 30px 0px rgba(0,0,0,.75);
`
const HeadlineWrap = styled.div`
  ${tw`2xl:text-6xl text-4xl relative z-10 font-ubuntu font-black md:text-center px-offset md:px-offsetMd lg:px-offsetLg flex flex-col items-center`}
`
const DescriptionGrid = styled.div`
  ${tw`
    pt-c120 md:pt-c96 2xl:pt-c120
    grid
    grid-cols-none
    md:grid-cols-2
    px-offset md:px-0
    md:px-0
  `}
`
const Description = styled.div`
  ${tw`
		text-white
		relative
		z-10
    pt-6
    font-normal
    md:col-start-2
	`}
  & strong {
    ${tw`font-black`}
  }
`
const BigArrow = styled.div`
  ${tw`text-8xl lg:text-14xl font-firacode absolute right-0 top-0 transform translate-x-1/2 rotate-180`}
`

const WpAcfContactModuleBlock = ({ moduleData, isHome }) => {
  const blockData = moduleData.acfContactBlock
  const sectionAttributes = moduleData.attributes
  const defaultId = `contact-section-${Math.floor(Math.random() * 100)}`

  const sectionRef = useRef()
  const { openContactModal } = useStateContext()
  const formClick = () => {
    openContactModal()
  }

  return (
    <div id="contact-wrapper" className="wrapper relative">
      <Section
        ref={sectionRef}
        id={`${sectionAttributes.anchor || defaultId}`}
        style={{
          backgroundColor: blockData.backgroundColor,
        }}
      >
        <BigArrow
          style={{
            color: blockData.symbolsColor,
          }}
        >
          {parse(`->>`)}
        </BigArrow>

        {isHome && (
          <div className="floating-symbol absolute -top-80 right-80 mx-auto z-10 flex flex-col items-center">
            <MovingElement
              speed={0.05}
              direction={{
                negative: "",
                axis: "y",
              }}
              content={false}
              className="absolute transform top-c80 left-0 right-0 mx-auto transition-all duration-300 ease-linear"
            >
              <span
                style={{
                  writingMode: "vertical-lr",
                  lineHeight: "1.6",
                }}
                className="scroll-element block font-firacode whitespace-nowrap text-6xl text-center font-black text-yellow absolute transform top-c80 mx-auto"
              >
                {parse(`->>-`)}
              </span>
            </MovingElement>
          </div>
        )}
        <HeadlineWrap>
          <h2 className="headline-section font-ubuntu font-bold relative">
            <span
              style={{
                color: blockData.symbolsColor,
              }}
              className="font-firacode text-5xl absolute transform top-1/2 -left-10 -translate-y-1/2 -translate-x-full"
            >
              {parse(`]|{}->`)}
            </span>
            <span
              style={{
                color: blockData.textColor,
              }}
              className="relative"
              data-start-symbol={blockData.startSymbol}
              data-end-symbol={blockData.endSymbol}
            >
              {parse(blockData.headline)}
            </span>
          </h2>

          <Button
            onClick={formClick}
            buttonText={blockData.button.title}
            buttonHref={"/"}
            buttonClasses="bg-yellow text-black mr-auto"
          />
        </HeadlineWrap>

        <DescriptionGrid>
          {blockData.description !== null && (
            <Description className="copy">
              {parse(blockData.description)}
            </Description>
          )}
        </DescriptionGrid>
      </Section>
    </div>
  )
}

WpAcfContactModuleBlock.propTypes = {
  moduleData: PropTypes.object,
}

WpAcfContactModuleBlock.defaultProps = {
  moduleData: ``,
}

export default WpAcfContactModuleBlock
